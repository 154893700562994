
import { Component, Vue } from "vue-property-decorator";
import { orders } from "@/services/api/orders.service";
import { OrderStatus } from "@/models/utils/order-status.enum";
import { Order } from "@/models/entities/order.interface";
import UserOrder from "./UserOrder.vue";

@Component({
  components: { UserOrder },
})
export default class MyOrders extends Vue {
  private orders: Order[] = [];
  private dates: string[] = null;

  private created(): void {
    this.getData();
  }

  private async getData(): Promise<void> {
    await this.getOrders();
  }

  private async getOrders(): Promise<void> {
    const datesCheck = this.dates && this.dates.length == 2;
    const o = await orders.getAllOrdersCurrentClient(
      datesCheck ? this.dates[0].toDate() : null,
      datesCheck ? this.dates[1].toDate() : null,
      OrderStatus.NONE
    );
    o.data.forEach((order) => {
      order.formattedConfirmationDate = order.confirmationDate
        .toDate()
        .toFormat("dd MMMM yyyy");
    });
    this.orders = o.data;
  }
}
