
import { Component, Vue } from "vue-property-decorator";
import { ProfileMenuItem } from "@/models/utils/profile-menu-interface";
import PersonalInfo from "./Components/PersonalInfo/PersonalInfo.vue";
import Favorites from "./Components/Favorites/Favorites.vue";
import { ProfileItemType } from "@/helpers/types";
import MyOrders from "./Components/MyOrders/MyOrders.vue";

@Component({
  components: {
    PersonalInfo,
    Favorites,
  },
})
export default class UserProfile extends Vue {
  private menuItems: ProfileMenuItem[] = [
    {
      key: "personal-data",
      label: "Dati personali",
      icon: "user",
      active: false,
      component: PersonalInfo,
    },
    {
      key: "favorites",
      label: "Preferiti",
      icon: "heart",
      active: false,
      component: Favorites,
    },
    {
      key: "orders",
      label: "I tuoi ordini",
      icon: "cost-note",
      active: false,
      component: MyOrders,
    },
  ];
  private component: unknown = null;

  private created(): void {
    const initialMenuItem: ProfileItemType = this.$route.params
      .tab as ProfileItemType;
    var menuItem: ProfileMenuItem = null;
    if (initialMenuItem)
      menuItem = this.menuItems.find(
        (menuItem: ProfileMenuItem) => menuItem.key === initialMenuItem
      );
    else menuItem = this.menuItems[0];
    menuItem.active = true;
    this.component = menuItem.component;
  }

  private menuItemClick(menuItem: ProfileMenuItem): void {
    this.resetActive();
    menuItem.active = true;
    this.component = menuItem.component;
  }

  private resetActive(): void {
    this.menuItems.forEach((menuItem: ProfileMenuItem) => {
      menuItem.active = false;
    });
  }
}
