
import { Component, Vue } from "vue-property-decorator";
import { UserProfileData } from "@/models/entities/user-profile-data.interface";
import OverlayModule from "@/store/modules/overlay.module";
import { userService } from "@/services/api/users.service";
import { getModule } from "vuex-module-decorators";
import store from "@/store";

const overlayModule = getModule(OverlayModule, store);

@Component
export default class PersonalInfo extends Vue {
  private userProfileData = {} as UserProfileData;

  private get userInfo(): string {
    if (this.userProfileData.isPrivate)
      return `${this.userProfileData.firstName} ${this.userProfileData.lastName}`;
    return `${this.userProfileData.businessName} - P.IVA: ${this.userProfileData.vatNumber}`;
  }

  private mounted(): void {
    this.getProfileData();
  }

  private async getProfileData(): Promise<void> {
    overlayModule.showOverlay();
    const r = await userService.getProfileData();
    this.userProfileData = r.data;
  }

  private openMailClient(): void {
    window.open(
      "mailto: Leccolatte.commerciale@gmail.com?subject=Richiesta di modifica dei dati personali"
    );
  }
}
